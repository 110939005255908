import { HistoryTabTypeEnum } from '@/constants/c2c/advertise'

/** c2c-去充值 */
export function getC2cTopupPageRoutePath() {
  return `/assets/main/deposit`
}

/** c2c-发布广告 */
export function getC2cPostAdvPageRoutePath() {
  return `/c2c/post/adv`
}

/**
 * c2c-商家广告单记录
 * @param type 0 默认进入我的广告单
 */
export function getC2cAdsHistoryPageRoutePath(type?: HistoryTabTypeEnum) {
  let url = '/c2c/ads/history'
  if (type) {
    url = `${url}?&type=${type}`
  }
  return url
}

/** c2c-广告单详情 */
export function getC2cAdvDetailPageRoutePath(id: string) {
  return `/c2c/adv/detail/${id}`
}

/** c2c-成为商家首页 */
export function getC2cMerchantPageRoutePath() {
  return `/c2c/merchant`
}

/** c2c-申请成为商家 */
export function getC2MerchantApplicationPageRoutePath() {
  return `/c2c/merchant/application`
}

/** c2c-历史订单 */
export function getC2cHistoryRecordsPageRoutePath() {
  return `/c2c/orders`
}
/** c2c-订单流程 */
export function getC2cOrderDetailPageRoutePath(id?: string) {
  return `/c2c/orders/detail/${id}`
}

/** c2c-bid 交易 */
export function getC2cOrderBidPageRoutePath() {
  return `/bid/trade`
}

/** c2c-bid 交易详单 */
export function getC2cOrderBidDetailPageRoutePath(querystring: string) {
  return `/bid/trade/detail?${querystring}`
}

/** 第三方交易 */
export function getThirdPartyPaymentPageRoutePath() {
  return `/c2c/third-party-payment`
}

/** c2c-快捷交易 */
export function getC2cFastTradePageRoutePath() {
  return `/c2c/fast-trade`
}

/** c2c-交易 */
export function getC2cTradeRoutePath() {
  return '/c2c/trade'
}

/**
 * 获取 c2c 中心页面路径
 * @param uid 查看他人 uid 查看自己不传
 * @param type 0 默认进入我的广告单
 * @returns url
 */
export function getC2CCenterPagePath(uid = '', type = 0) {
  let url = '/c2c/center'

  // 使用数组来收集查询参数
  const queryParams: string[] = []

  if (uid) {
    queryParams.push(`uid=${uid}`)
  }

  if (type) {
    queryParams.push(`type=${type}`)
  }

  // 使用 join 连接查询参数
  if (queryParams.length > 0) {
    url += `?${queryParams.join('&')}`
  }

  return url
}
